var gmaps = require('google-maps');
gmaps.KEY = 'AIzaSyD7xdpNyeH8MXch_lzDLEpg9n4C6yxraFM';

(function() {
    if ($('#maps').length) {

        gmaps.load(function(google) {
            var el = document.getElementById('maps');
            var lat = 51.210474,
            long = 3.225812;
            var center = new google.maps.LatLng(lat, long);
              var icon = {
                   url: '/bundles/sitefrontend/img/pin.svg',
                scaledSize: new google.maps.Size(38, 36)
            };


            var options = {
                zoom: 16,
                center: center,
                styles: [{
                    stylers: [{
                        saturation: -100
                    }]
                  }]
            };

            var map = new google.maps.Map(el, options);


            google.maps.event.addDomListener(window, "resize", function() {
                var center = map.getCenter();
                google.maps.event.trigger(map, "resize");
                map.setCenter(center);
            });

            var marker = new google.maps.Marker({
                position: center,
                map: map,
                icon: icon
            });
        });
    }
})();
