require("./plugins/bootstrap.js");
require("./plugins/bootstrap-datepicker.js");
require("./plugins/slick.js");
require("./plugins/throttle-debounce.min.js");
require("./modules/fastbooker.js");
//require("./plugins/magnific-popup.js");
//require("./modules/dropdown.js");


require("./modules/forms.js");
if ($('#maps').length) {
    require("./modules/location.js");
}

$('.js-hero__slider').slick({
    autoplay: true,
    fade: true,
});

$('.js-question-carousel').slick({

    fade: true,
    arrows: false,
    dots: true
});

$('.js-subpage-slider').slick({
    fade: true,
    arrows: true,
    autoplay: true,
    nextArrow: '<div class="arrow arrow--next"> </div>',
    prevArrow: '<div class="arrow arrow--prev"> </div>'
});

$('.js-question').on('click', function(event) {
    event.preventDefault();

    var questionAsked = $(this).children('.js-question-text').text().trim();

    console.log(questionAsked);

    $('.js-form-message').val(questionAsked);

    var contactPos = $('#main-contact-form').offset();
    $('html, body').animate({ scrollTop: contactPos.top - 200 }, "slow");

    $('.js-form-naam').focus();
});



$(window).on("scroll resize", function() {
    $('.js-clock__hours').html('09');
    $('.js-clock__minutes').html('00');


    var clockOuterWidth = $('.js-clock').outerWidth();
    var clockOuterHeight = $('.js-clock').outerHeight();

    var fastbookerOuterHeight = $('.js-fastbooker').children('.fastbooker__form').outerHeight();
    var fastbookerOuterWidth = $('.js-fastbooker').width();

    var containerOuterWidth = $('.js-fastbooker').parent().width();

  //  clockWidth = containerOuterWidth - fastbookerOuterWidth;
  //  clockHeight = fastbookerOuterHeight;

    $('.js-clock').outerWidth(255);
    //$('.js-clock').height(clockHeight);


    var fhw = window.outerWidth - 285;
  //  $('.fastbooker__home').css('width', fhw);
  //  $('.fastbooker__home').width = containerOuterWidth - 255;

    var clock = $('.js-clock').offset();



    $('.time-block').each(function(e) {
        // console.log($(this).offset().top);
    //    console.log(this.children[0].children[0].innerText);


        if (clock.top >= $(this).offset().top) {
            var currentTime = ($(this).find('.js-time').attr('data-showtime'));
            var splitTime = currentTime.split(":");
            var currentText = this.children[0].children[0].innerText;

            $('.js-clock__hours').html(splitTime[0]);
            $('.js-clock__minutes').html(splitTime[1]);
            $('.js-clock__text').html(currentText);


            return;
        }
    });
});

$(document).ready(function() {
    $(window).trigger('scroll');

});




if ($('.js-clock').length) {

    var stickyOffset = $('.js-clock-container').offset().top;

    $(window).scroll(function() {

        var sticky = $('.js-clock'),
            scroll = $(window).scrollTop();

        if (scroll >= stickyOffset) {
            sticky.css('position', 'fixed');
            sticky.css('top', '0');
            //sticky.find('.js-clock').css('top', '0');
        } else {
            sticky.css('position', 'static');
           // sticky.find('.js-clock').css('top', '22.5em');
        }
    });

}



if ($('.js-fastbooker').length) {

    var stickyFastOffset = $('.js-fastbooker').children('.fastbooker__form').offset().top;

    $(window).scroll(function() {

        var stickyFast = $('.js-fastbooker').children('.fastbooker__form'),
            scroll = $(window).scrollTop();

        if (scroll >= stickyFastOffset) {
          stickyFast.addClass('sticky');
            stickyFast.css('position', 'fixed');
            stickyFast.css('top', '0');
            stickyFast.css('margin-top', '0');

        } else {
          stickyFast.removeClass('sticky');
            stickyFast.css('position', 'absolute');
            stickyFast.css('margin-top', '-3em');

        }
    });

}

$('.js-mobile-menu').on('click', toggleMenu)

function toggleMenu(e) {
  $('.js-mobile-nav').toggleClass('isOpen');
  $('.js-mobile-menu').toggleClass('isToggled');
  //$('html, body').toggleClass('u-fixed');
}
